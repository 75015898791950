<template>
  <el-menu
    style="display: inline-block"
    mode="horizontal"
    router
    :default-active="$route.path"
  >

    <el-submenu
      v-show="showAdministration"
      index="1"
    >
      <template slot="title">Администрирование</template>

      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.RBAC.LIST)"
        index="/settings/rbac"
        :route="{name: 'Rbac'}"
      >
        Настройка ролей
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.USER.CHIEFS_LIST_BY_TYPE)"
        index="/settings/chiefs"
        :route="{name: 'Chiefs'}"
      >
        Настройка орг. структуры
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, 'setting.scales')"
        index="/settings/scales"
        :route="{name: 'Scales'}"
      >
        Настройка шкал
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.GROUP.LIST)"
        index="/settings/groups"
        :route="{name: 'Groups'}"
      >
        Настройка групп
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.MASS_ACTIONS.CREATE)"
        index="/settings/mass-actions"
        :route="{name: 'MassActions'}"
      >
        Массовые действия
      </el-menu-item>
      <MenuItem
        v-show="$canAndRulesPass(user, $permissions.USER.DEPUTIES_SEE_IN_MENU)"
        index="/settings/deputies"
        :route="{name: 'Deputies'}"
      >
        Делегирование доступа
      </MenuItem>
      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.SETTING.UPDATE)"
        index="/settings/dynamic"
        :route="{name: 'DynamicSettings'}"
      >
        Прочее
      </el-menu-item>
    </el-submenu>


    <el-submenu
      v-show="$canAndRulesPass(user, $permissions.LIBRARY.SEE_IN_MENU)"
      index="2"
    >
      <template slot="title">Библиотеки</template>

      <el-menu-item
        v-for="(typeConfig, typeKey) in $companyConfiguration.libraries.getTypes()"
        :key="typeKey"
        :index="'/library/'+typeKey"
        :route="{name: 'Library', params: { type: typeKey }}"
      >
        {{ typeConfig.showName }}
      </el-menu-item>
    </el-submenu>

    <el-menu-item
      v-show="$canAndRulesPass(user, $permissions.CARD.LIST)"
      index="/cards"
      :route="{name: 'Cards'}"
    >
      Карты
    </el-menu-item>
    <el-menu-item
      v-show="$canAndRulesPass(user, $permissions.MATRIX.VIEW)"
      index="/matrix"
      :route="{name: 'Matrix'}"
    >
      Матрица КПЭ
    </el-menu-item>
    <el-menu-item
      v-show="$canAndRulesPass(user, $permissions.USER.ORGSTRUCT_VIEW)"
      index="/orgstruct"
      :route="{name: 'Orgstruct'}"
    >
      Орг. структура
    </el-menu-item>
    <el-menu-item
      v-show="$canAndRulesPass(user, $permissions.REPORT.USE)"
      index="/report"
      :route="{name: 'Report'}"
    >
      Конструктор отчетов
    </el-menu-item>

    <MenuItem
      v-show="$canAndRulesPass(user, $permissions.HOME_DASHBOARD.SEE_IN_MENU)"
      index="/dashboards"
      :route="{name: 'Dashboards'}"
    >
      Дашборды
    </MenuItem>

  </el-menu>
</template>

<script>
import {mapGetters} from "vuex";
import MenuItem from "@/components/header/MenuItem.vue";

export default {
  name: "menu-component",
  components: {MenuItem},

  props: {},

  watch: {},
  computed: {
    ...mapGetters(['user']),
    showAdministration(){
      return this.$canAndRulesPass(this.user, this.$permissions.RBAC.LIST)
          || this.$canAndRulesPass(this.user, this.$permissions.USER.CHIEFS_LIST_BY_TYPE)
          || this.$canAndRulesPass(this.user, 'setting.scales')
          || this.$canAndRulesPass(this.user, this.$permissions.GROUP.LIST)
          || this.$canAndRulesPass(this.user, this.$permissions.MASS_ACTIONS.CREATE)
          || this.$canAndRulesPass(this.user, this.$permissions.SETTING.UPDATE)
    }
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>


<style lang="scss">

</style>